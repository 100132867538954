interface ButtonProps {
  onClick: () => void
  children: React.ReactNode
  className?: string
}

export function Button({ onClick, children, className = '' }: ButtonProps) {
  return (
    <button className={['bg-gray-400', ' ', ...className].join('')} onClick={onClick}>
      {children}
    </button>
  )
}
