import { ArrowsRightLeftIcon } from '@heroicons/react/24/outline'
import React from 'react'

export function Layout({ children }: React.PropsWithChildren<any>) {
  return (
    <div className="min-h-full">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-24 sm:h-32 justify-between">
          <div className="flex">
            <div className="flex flex-shrink-0 items-center">
              <ArrowsRightLeftIcon className="block h-8 w-auto lg:hidden text-red-400" />
              <ArrowsRightLeftIcon className="hidden h-8 w-auto lg:block text-red-400" />
              <h1 className="ml-4 font-bold text-lg text-red-400">Property Insights</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="min-h-[calc(100vh-96px)] sm:min-h-[calc(100vh-128px)] gradient">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">{children}</div>
      </div>

      <footer className="bg-red-400">
        <div className="mx-auto max-w-7xl pb-24 py-16 px-4 sm:px-6 md:flex md:justify-end lg:px-8">
          <div className="flex justify-center space-x-6 md:order-2"></div>
          <div className="mt-8 md:order-1 md:mt-0">
            <p className="text-center text-base  text-white">
              &copy; 2022 Property Insights. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  )
}
