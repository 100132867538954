import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { Button } from 'components/Button'
import { GrowthTiles } from 'components/GrowthTiles'
import { PostcodeInfo } from 'utils/postcode-service'

type Props = {
  postcode: string
  results: PostcodeInfo
  clear: () => void
}

export function SearchResults({ postcode, results, clear }: Props) {
  return (
    <div className="overflow-hidden bg-white px-6 py-6 border-0 border-gray-200 rounded-xl">
      <Button onClick={clear} className="bg-transparent">
        <div className="flex items-center">
          <ArrowLeftIcon className="w-5 h-5 bg-white text-gray-600" />
          <span className="ml-1 font-semibold text-gray-600 text-sm">Back</span>
        </div>
      </Button>

      {!!results && (
        <>
          <div className="mt-8 sm:mt-12">
            <p className="text-2xl sm:text-4xl">
              <span className="font-bold text-red-400">{postcode}</span>{' '}
              <span className="font-bold text-gray-900">{results.region}</span>
            </p>
          </div>

          <div className="mt-8 sm:mt-12">
            <p className="text-2xl font-semibold text-gray-900 mb-4">Price growth</p>
            <GrowthTiles results={results.growth} />
          </div>
        </>
      )}

      <p className="font-bold text-gray-900 text-3xl"></p>
    </div>
  )
}
