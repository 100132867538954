import { Button } from 'components/Button'

type Props = {
  postcode: string
  setTerm: (term: string) => void
  searchPostcode: () => void
  error: string
}

export function SearchPanel({ postcode, setTerm, searchPostcode, error }: Props) {
  return (
    <div className="overflow-hidden bg-white">
      <div className="px-6 py-2 text-center">
        <p className="font-bold text-gray-90 text-3xl">View area growth</p>
        <div className="mt-6 flex flex-col mx-auto items-center">
          <input
            type="text"
            name="postcode"
            id="postcode"
            placeholder="Enter a postcode, e.g. SE1"
            autoComplete="off"
            value={postcode}
            onChange={(e) => setTerm(e.target.value.toUpperCase())}
            className="w-full max-w-lg rounded-xl border-gray-300 shadow-md focus:drop-shadow-md focus:border-gray-300 focus:ring-0 ring-0 active:ring-0 sm:max-w-xs sm:text-sm transition-all outline-none ease-in-out duration-150"
          />
          <Button
            onClick={() => searchPostcode()}
            className="bg-red-400 text-white mt-12 rounded-xl px-6 py-2 w-full sm:w-48"
          >
            Search
          </Button>
          {error && <p className="mt-12">{error}</p>}
        </div>
      </div>
    </div>
  )
}
