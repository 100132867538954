import demographicData from 'data/demographics.json'
import growthData from 'data/growth_rental.json'

export type PostcodeGrowth = {
  '1yr': string
  '3yr': string
  '5yr': string
}
export type PostcodeInfo = {
  region: string
  growth: PostcodeGrowth
}
type ValidPostcode = keyof typeof growthData

function getPostcodeInfo(postcode: string): PostcodeInfo | null {
  const growth = growthData[postcode.toUpperCase() as ValidPostcode]
  const demographics = demographicData[postcode.toUpperCase() as ValidPostcode]

  if (!growth || !demographics) {
    return null
  }

  return {
    region: demographics['UK region'],
    growth: {
      '1yr': growth['1yr'] || '-',
      '3yr': growth['3yr'] || '-',
      '5yr': growth['5yr'] || '-',
    },
  }
}

const trimPostcode = (postcode: string) => {
  const regex = /^([A-Z]{1,2}[0-9][A-Z0-9]?) ?([0-9][A-Z]{2})?$/
  const postcodeMatch = postcode.match(regex) || []
  return postcodeMatch?.length > 1 ? postcodeMatch[1] : postcode
}

export { getPostcodeInfo, trimPostcode }
