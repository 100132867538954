import { Layout } from 'components/Layout'
import { SearchAndResults } from 'components/SearchAndResults'

function App() {
  return (
    <div className="App">
      <Layout>
        <SearchAndResults />
      </Layout>
    </div>
  )
}

export default App
