import { PostcodeGrowth } from 'utils/postcode-service'

type Props = {
  results: PostcodeGrowth
}

export function GrowthTiles({ results }: Props) {
  return (
    <div className="space-y-6 sm:space-x-6 sm:space-y-0 flex flex-col sm:flex-row">
      <Tile title="1 year" value={results['1yr']} />
      <Tile title="3 years" value={results['3yr']} />
      <Tile title="5 years" value={results['5yr']} />
    </div>
  )
}

function Tile({ title, value }: { title: string; value: string }) {
  return (
    <div className="bg-white w-full max-w-xs sm:w-40 border-2 border-gray-600 rounded-xl px-6 py-4">
      <p className="text-gray-500 font-bold text-sm">{title}</p>
      <p className="text-gray-900 text-5xl font-semibold mt-2">{value}</p>
    </div>
  )
}
