import { SearchPanel } from 'components/SearchPanel'
import { SearchResults } from 'components/SearchResults'
import { useState } from 'react'
import { getPostcodeInfo, PostcodeInfo, trimPostcode } from 'utils/postcode-service'

const usePostcodeSearch = () => {
  const [term, setTerm] = useState('')
  const [trimmedTerm, setTrimmedTerm] = useState('')
  const [results, setResults] = useState<PostcodeInfo | null>(null)
  // const [average, setAverage] = useState<any>('')
  // const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const searchPostcode = () => {
    if (!term.length) {
      setResults(null)
    }

    const trimmedPostcode = trimPostcode(term)

    setTrimmedTerm(trimmedPostcode)
    const results = getPostcodeInfo(trimmedPostcode)
    if (results) {
      setErrorMessage('')
      setResults(results)
      // setAverage(Object.entries(lookup.average))
    } else {
      setErrorMessage(`No data available for this postcode`)
      setResults(null)
    }
  }

  const clearResults = () => {
    setResults(null)
  }

  return {
    term,
    results,
    clearResults,
    setTerm,
    searchPostcode,
    trimmedTerm,
    errorMessage,
  }
}

export function SearchAndResults() {
  const {
    term,
    results,
    setTerm,
    clearResults,
    searchPostcode,
    trimmedTerm,
    errorMessage,
  } = usePostcodeSearch()

  return (
    <>
      {results ? (
        <SearchResults postcode={trimmedTerm} results={results} clear={clearResults} />
      ) : (
        <SearchPanel
          postcode={term}
          setTerm={setTerm}
          searchPostcode={searchPostcode}
          error={errorMessage}
        />
      )}
    </>
  )
}
